<template>
  <div>
    <div class="search_bar">
      <v-text-field
        v-model="filter.search"
        label="Поиск"
        height="44"
        hide-details
        outlined
        dense
        prepend-inner-icon="mdi-magnify"
        @change="handleChange"
      >
      </v-text-field>
      <v-autocomplete
        v-model="filter.type"
        :items="filterItems"
        label="Фильтр"
        item-text="name"
        item-value="id"
        height="44"
        hide-details
        outlined
        dense
        @change="handleChange"
      >
      </v-autocomplete>
      <v-btn @click="getList" color="primary" height="44" width="160">
        Найти
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items.results"
      disable-sort
      :options.sync="options"
      :server-items-length="items.count"
      @click:row="goPage"
      :loading="loading"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ $moment(new Date(+item.created_at * 1000)).format("DD.MM.YYYY") }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ statuses[item.status] }}
      </template>
      <template v-slot:[`item.service_type`]="{ item }">
        {{ types[item.service_type] }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import requests from "@/utils/requests.js"
export default {
  mixins: [requests],
};
</script>

<style lang="scss" scoped>
.search_bar {
  display: grid;
  grid-template-columns: 1fr 200px 160px;
  align-items: center;
  margin-bottom: 40px;
}
</style>
