import pagination from "@/utils/pagination";
import { orderRequestService } from "@/services/orderRequests.service.js";
export default {
  mixins: [pagination],
  data: () => ({
      statuses: {
        "confirmed": "Подтверждено",
        "not confirmed": "Не подтверждено",
        "refunded": "Возвращено",
        "canceled": "Отклонено",
        "refund": "Запрос на возврат",
      },
    filterItems: [
      {
        name: "Все заявки",
        id: "all",
      },
      {
        name: "Мои заявки",
        id: "mine",
      },
      {
        name: "Заявки турагента",
        id: "touragent",
      },
    ],
    filter: {},
    headers: [
      { text: "№", value: "id" },
      { text: "Туроператор", value: "tour_operator" },
      { text: "Турагент", value: "tour_agent" },
      { text: "Сотрудник", value: "user" },
      { text: "Турист", value: "tourist" },
      { text: "Сумма", value: "total_price" },
      { text: "Дата", value: "created_at" },
      { text: "Тип услуги", value: "service_type" },
      { text: "Баланс агента", value: "balance" },
      { text: "Статус оплаты", value: "payment_status" },
      { text: "Статус", value: "status" },
    ],
    items: [],
    types: {
      accommodation: "Проживание",
      railway: "Ж/Д",
      bus: "Автобусы",
      tour: "Туры",
    },
    loading: false, 
  }),
  methods: {
    handleChange(){
      this.options.page = 1
      this.options.itemsPerPage = 10
    },
    async getList() {
        this.loading = true
        let obj = {
            requests_all: null, 
            requests_accepted: 'confirmed',
            requests_unaccepted: 'not confirmed',
            requests_return_request: 'refund',
            requests_returned: 'refunded',
            requests_rejected: 'canceled',
        }
        let params = {
          page: this.options.page || 1,
          size: this.options.itemsPerPage || 10,
          status: obj[this.$route.name],
          type: this.filter.type,
          search: this.filter.search
        };
        if (this.filter.search) params.search = this.filter.search;
        if (this.filter.type) params.type = this.filter.type;
        try{
          let res = await orderRequestService.getOrderRequests(params);
          this.items = res;
          this.$store.commit('lists/SET_TRIGGER_REQUEST')
        } catch(e) {
          console.log(e);
        } finally{
          this.loading = false
        }
      },
    goPage(item) {
        let obj = {
            requests_all: 'all', 
            requests_accepted: 'accepted',
            requests_unaccepted: 'unaccepted',
            requests_return_request: 'return_request',
            requests_returned: 'returned',
            requests_rejected: 'rejected',
        }
      this.$router.push(`/requests/${obj[this.$route.name]}/${item.id}`);
    },
  },
};